@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

:root {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;

  --desktop-width: 1518px;
  --bg-nosotros-color: #eaedf4;
  --btn-prime-text: #ffffff;
  --prime-color: #1a3eea;
  --footer-color: #e80041;
  --font-family: "Figtree", sans-serif;
  --roboto: "Roboto Condensed", sans-serif;
  --placeholder-text: #595e62;
  --placeholder-color: #e3e3e3;
  --border-row: #d0d4de;
  --gris-medio: #9e9e9e;
  --gris-claro: #eaedf4;
  --gris-fuerte: #595e62;
  --gris-invisible: #f6f6f6;
}

/*----------------------------spinner-------------------*/
.spinner {
  --size: 30px;

  --first-block-clr: #005bba;

  --second-block-clr: #fed500;

  --clr: #111;

  width: 100px;

  height: 100px;

  position: relative;
}

.spinner::after,
.spinner::before {
  box-sizing: border-box;

  position: absolute;

  content: "";

  width: var(--size);

  height: var(--size);

  top: 50%;

  animation: up 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;

  left: 50%;

  background: #1a3eea;
}

.spinner::after {
  background: #1a3eea;

  top: calc(50% - var(--size));

  left: calc(50% - var(--size));

  animation: down 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes down {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

@keyframes up {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}

/* ----------------fonts---------------- */

/* variables de texto*/

.b-text {
  font-family: Figtree;
  font-weight: 700;
}

.r-text {
  font-family: Figtree;
  font-weight: 400;
}

.sb-text {
  font-family: Figtree;
  font-weight: 500;
}

.italic {
  font-style: italic;
}

/* tamaños de texto*/

.tt-principal {
  font-size: 60px;
  line-height: 60px;
}

.tt-second {
  font-size: 38px;
  line-height: 40.5px;
}

.tt-small {
  font-size: 24px;
  line-height: 29px;
}

.tx-principal {
  font-size: 22px;
  line-height: 26px;
}

.tx-medium {
  font-size: 18px;
  line-height: 22px;
}

.tx-small {
  font-size: 16px;
  line-height: 18.5px;
}

.tx-min {
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto Condensed;
  font-weight: 400;
}

/* ----------------colors---------------- */

.primary-c {
  color: #fdd71c; /* amarillo principal*/
}

.black-c {
  color: #080c0d; /* negro */
}

.darkgrey-c {
  color: #595e62; /* gris medio */
}

.softgrey-c {
  color: #eaedf4; /* gris claro */
}

.accent-c {
  color: #e80041; /* rojo */
}

.alternative-c {
  color: #e1c224; /* amarillo alternativo */
}

.white-c {
  color: #ffffff; /* blanco */
}

/* ----------------buttons---------------- */

/* tamaño */

.btn {
  font-family: Figtree;
  font-weight: 700;
  margin: 4px 0px; /* ignorar esto, lo usé para esta prueba */
}

.large-btn {
  padding: 11px 24px;

  font-size: 18px;
  line-height: 18px;
}

.medium-btn {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 16px;
}

.small-btn {
  padding: 6px 12px;

  font-size: 14px;
  line-height: 14px;
}

/* prioridad */

.primary-btn {
  background-color: var(--prime-color);
  color: white;
  border-radius: 8px;
  font-weight: 700;
  outline: none;
}

.primary-btn-big {
  height: 42px;
  border-radius: 6px;
  font-weight: 700;
  color: white;
  cursor: pointer;
  text-align: center;
  background-color: var(--prime-color);
  border: none;
  outline: none;
}

.primary-btn-reverse {
  border-radius: 8px;
  font-weight: 700;
  background-color: white;
  color: var(--prime-color);
  border: 1px solid var(--prime-color);
}
.primary-btn-reverse-big {
  height: 42px;
  border-radius: 6px;
  font-weight: 700;
  background-color: white;
  color: var(--prime-color);
  border-color: var(--prime-color);
}
.second-btn {
  color: var(--gris-medio);
  background-color: var(--gris-claro);
  border-color: var(--gris-claro);
}

/* estado */

.primary-btn-big:hover,
.primary-btn-big:focus,
.primary-btn-reverse:hover,
.primary-btn-reverse:focus,
.primary-btn-reverse-big:hover,
.primary-btn-reverse-big:focus,
.primary-btn:hover,
.primary-btn:focus {
  color: white;
  background-color: #595e62;
  border-color: transparent;
}

.primary-btn:disabled {
  color: #595e62;
  background-color: #f3f3f3;
  border-color: transparent;
  font-weight: 500;
}

.second-btn:active {
  color: #080c0d;
  background-color: #eaedf4;
  border-color: #080c0d;
}

.second-btn:disabled {
  color: #a1a1a1;
  background-color: #ffffff;
  border-color: #a1a1a1;

  font-weight: 500;
}

/* ----------------espacios---------------- */

.spacing-16 {
  height: 16px;
}
.spacing-24 {
  height: 24px;
}
.spacing-32 {
  height: 32px;
}
.spacing-48 {
  height: 48px;
}
.spacing-64 {
  height: 64px;
}
.spacing-96 {
  height: 96px;
}

/***************/
.error-message {
  color: red;
  font-family: "Figtree", sans-serif;
  margin: 5px;
  font-size: 12px;
}
