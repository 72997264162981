.swal2-popup {
    background: #f8f9fa !important;
    width: 380px !important;
    padding: 20px 15px 30px 15px !important;
}

.swal2-title {
    font-family: 'Figtree', sans-serif !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    margin-bottom:8px !important;
    color: black !important;
}

.swal2-html-container {
    font-family: 'Figtree', sans-serif !important;
    font-size: 15px !important;
    font-weight: 400 !important;

    padding: 0px !important;
    color: black !important;
}

.swal2-icon{
    margin: 2px auto 2px !important;
}

@media (max-width:520px){
    .swal2-popup {
        width: 200px !important;
        padding: 20px 7px !important;
    }
    .swal2-html-container {
        font-size: 14px !important;;
    }
    .swal2-title {
        font-size: 16px !important;
    }
    .swal2-icon{
        margin: 0px auto 2px !important;
        scale: 0.7;
    }
}